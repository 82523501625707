import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/Layout"
import ListMattress from "../components/ListMattress"
import CompareFilter from "../components/CompareFilter"

import { addOrRemove, filterArray, calculateFinalPrice } from "../lib/utils"

import Seo from "../components/Seo/Seo"

// markup
const Mattresses = () => {

  const allMattresses = useStaticQuery(graphql`
    query {
        allDatoCmsMattress(
            filter: {
                locale: {eq: "en"}, 
                location: {
                    websiteUrl: {eq: "us.mattress.zone"}
                }
            }, 
            sort: {
                fields: name, 
                order: ASC
                }
            ) {
            nodes {
              name
              slug
              location {
                  websiteUrl
              }
              mattressImage {
                  gatsbyImageData(
                      imgixParams: {auto: "format", fit: "crop", h: "125", w: "250", colors: 10, cornerRadius: "10,10,10,10", mask: "corners"}
                      placeholder: BLURRED
                  )
              }
              id
              discountFlat
              discountPercentage
              brand {
                slug
                discountFlat
                discountPercentage
              }
              mattressType {
                slug
              }
              buttons {
                price
                salePrice
                size {
                  id
                  sizeName
                }
              }
            }
        }
        theBrand: allDatoCmsMattress(
            filter: {location: {websiteUrl: {eq: "us.mattress.zone"}, locale: {eq: "en"}}}
        ) {
            distinct(field: brand___slug)
        }
        theMattressType: allDatoCmsMattress(
            filter: {location: {websiteUrl: {eq: "us.mattress.zone"}, locale: {eq: "en"}}}
        ) {
            distinct(field: mattressType___slug)
        }
    }
  `)

  //used to control the useEffect hook since the other items are arrays
  const [trigger, setTrigger] = useState(0)
  const [searchTerm, setSearchTerm] = useState("")
  const [sizes, setSizes] = useState([])
  const [mattressTypes, setMattressTypes] = useState([])
  const [brands, setBrands] = useState([])
  const [prices, setPrices] = useState([])

  /*const tempAllItems = allMattresses.allDatoCmsMattress.nodes.map(node => {
    return calculateFinalPrice(node)
  })
  console.log(tempAllItems)*/
  const allItems = allMattresses.allDatoCmsMattress.nodes.map(node => {
    return calculateFinalPrice(node)
  })

  const [items, setItems] = useState(allItems)

  const sidebarToggle = (e) => {
    let sidebar = document.querySelector("aside")
    sidebar.classList.toggle("-translate-x-full")
  }

  const handleSearch = event => {
    setSearchTerm(event.target.value)
    setTrigger(prevValue => prevValue + 1)
  }

  const allFilterClickListener = (e, filterProp) => {
    //console.log(e.target.value)
    //console.log(e.target.checked)

    const name = e.target.value
    const checked = e.target.checked

    switch (filterProp) {
      case "size":
        setSizes(prevSizes => addOrRemove(prevSizes, name, checked))
        break;
      case "mattressType":
        setMattressTypes(prevMattressTypes => addOrRemove(prevMattressTypes, name, checked))
        break;
      case "brand":
        setBrands(prevBrands => addOrRemove(prevBrands, name, checked))
        break;
      case "price":
        setPrices(prevPrices => {
          const arrayPrice = name.split("-")
          return addOrRemove(prevPrices, { 'name': name, 'start': parseInt(arrayPrice[0]), 'end': parseInt(arrayPrice[1]) }, checked)
        })
        break;

      default:
        break;
    }

    setTrigger(prevValue => prevValue + 1)
  }

  useEffect(() => {
    const activeFilters = {
      brand: brands,
      mattressType: mattressTypes,
      size: sizes,
      price: prices,
      search: searchTerm.toLowerCase()
    }

    let filteredItems = filterArray(allItems, activeFilters)

    setItems(filteredItems)
    /* eslint-disable-next-line */
  }, [trigger])

  return (
    <Layout>

      <Seo
        title={null}
        description={null}
        image={null}
        pathname={'/mattresses'}
      />

      <div className="bg-zone-1">
        <div className="container mx-auto">
          <p className="p-6 lg:hidden">
            <button
              className="font-bold rounded-3xl py-2 px-4 text-sm bg-transparent text-zone-4 border-2 border-zone-10"
              onClick={sidebarToggle}
              onKeyDown={sidebarToggle}
            >
              Filter Mattresses
            </button>
          </p>

          <div className="flex items-stretch flex-col lg:flex-row relative">
            <CompareFilter
              className="flex-1"
              allFilterClickListener={allFilterClickListener}
              handleSearch={handleSearch}
              searchTerm={searchTerm}
              theBrand={allMattresses.theBrand}
            />

            <main className="flex-1 lg:flex-3">
              <ListMattress className="bg-zone-3 py-12 px-6" container={false} mattresses={items} />
            </main>

          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Mattresses
