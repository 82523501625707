import React from "react"

const CompareFilter = ({ allFilterClickListener, handleSearch, searchTerm, theBrand, className }) => {

    const handleToggle = (e) => {
        let button = e.target
        button.classList.toggle("toggle")
    }


    return (
        <aside className={`${className} flex flex-col pt-4 pr-4 pl-4 absolute w-full z-20 bg-zone-1 min-h-full duration-300 transform -translate-x-full lg:translate-x-0 lg:static lg:pl-0`}>
            <section className="border-b border-zone-5 mb-4">
                <input
                    type="text"
                    placeholder="Enter Mattress Name"
                    value={searchTerm}
                    onChange={handleSearch}
                    className="w-full mb-4 p-2 text-base text-zone-3 border border-zone-2 bg-zone-3"
                />
            </section>

            <section className="border-b border-zone-5 mb-4">
                <button onClick={handleToggle} className="filter-button font-bold font-grotesk">Size</button>
                <ul className="h-auto opacity-100 max-h-56 overflow-y-auto transition duration-300">
                    <li className="mb-3">
                        <label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="Twin" onChange={e => allFilterClickListener(e, "size")} /> Twin</label>
                    </li>
                    <li className="mb-3">
                        <label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="Twin XL" onChange={e => allFilterClickListener(e, "size")} /> Twin XL</label>
                    </li>
                    <li className="mb-3">
                        <label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="Full" onChange={e => allFilterClickListener(e, "size")} /> Full</label>
                    </li>
                    <li className="mb-3">
                        <label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="Queen" onChange={e => allFilterClickListener(e, "size")} /> Queen</label>
                    </li>
                    <li className="mb-3">
                        <label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="King" onChange={e => allFilterClickListener(e, "size")} /> King</label>
                    </li>
                    <li className="mb-3">
                        <label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="Cal King" onChange={e => allFilterClickListener(e, "size")} /> Cal King</label>
                    </li>
                </ul>
            </section>

            <section className="border-b border-zone-2 mb-4">
                <button onClick={handleToggle} className="filter-button font-bold font-grotesk">Mattress Type</button>
                <ul className="h-auto opacity-100 max-h-56 overflow-y-auto transition duration-300">
                    <li className="ml-1 mb-2 uppercase"><strong>Hybrid</strong></li>
                    <li className="mb-3"><label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="hybrid-coils-and-foam" onChange={e => allFilterClickListener(e, "mattressType")} /> Hybrid (coils and foam)</label></li>
                    <li className="mb-3"><label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="hybrid-coils-and-latex" onChange={e => allFilterClickListener(e, "mattressType")} /> Hybrid (coils and latex)</label></li>
                    <li className="mb-3"><label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="hybrid-coils-latex-and-foam" onChange={e => allFilterClickListener(e, "mattressType")} /> Hybrid (coils, latex and foam)</label></li>
                    <li className="mb-3"><label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="hybrid-coils-foam-polymer-grid" onChange={e => allFilterClickListener(e, "mattressType")} /> Hybrid (coils, foam &amp; polymer grid)</label></li>

                    <li className="mt-6 ml-1 mb-2 uppercase"><strong>Foam</strong></li>
                    <li className="mb-3"><label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="memory-foam" onChange={e => allFilterClickListener(e, "mattressType")} /> Memory foam</label></li>
                    <li className="mb-3"><label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="latex" onChange={e => allFilterClickListener(e, "mattressType")} /> Latex</label></li>
                    <li className="mb-3"><label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="latex-foam" onChange={e => allFilterClickListener(e, "mattressType")} /> Latex &amp; foam</label></li>
                    <li className="mb-3"><label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="all-foam" onChange={e => allFilterClickListener(e, "mattressType")} /> All Foam</label></li>
                    <li className="mb-3"><label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="foam-polymer-grid" onChange={e => allFilterClickListener(e, "mattressType")} /> Foam &amp; polymer grid</label></li>
                    <li className="mb-3"><label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="air-technology-latex-foam" onChange={e => allFilterClickListener(e, "mattressType")} /> Air Technology, Latex &amp; foam</label></li>

                    <li className="mt-6 ml-1 mb-2 uppercase"><strong>Other</strong></li>
                    <li className="mb-3"><label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="coils" onChange={e => allFilterClickListener(e, "mattressType")} /> Coils</label></li>
                    <li className="mb-3"><label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="polyethylene" onChange={e => allFilterClickListener(e, "mattressType")} /> Polyethylene</label></li>
                </ul>
            </section>

            <section className="border-b border-zone-2 mb-4">
                <button onClick={handleToggle} className="filter-button font-bold font-grotesk">Brand</button>
                <ul className="h-auto opacity-100 max-h-56 overflow-y-auto transition duration-300">
                    {theBrand.distinct.map((item, index) => {
                        return (
                            <li className="mb-3" key={`key-filter-brand-${index}`}>
                                <label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value={item} onChange={e => allFilterClickListener(e, "brand")} /> {item.replace(/-us/g, '').replace(/-/g, ' ')}</label>
                            </li>
                        )
                    }
                    )}
                </ul>
            </section>

            <section className="border-b border-zone-2 mb-4">
                <button onClick={handleToggle} className="filter-button font-bold font-grotesk">Price</button>
                <ul className="h-auto opacity-100 max-h-56 overflow-y-auto transition duration-300">
                    <li className="mb-3">
                        <label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="0-500" onChange={e => allFilterClickListener(e, "price")} /> $0 - $500</label>
                    </li>
                    <li className="mb-3">
                        <label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="501-1000" onChange={e => allFilterClickListener(e, "price")} /> $501 - $1000</label>
                    </li>
                    <li className="mb-3">
                        <label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="1001-2000" onChange={e => allFilterClickListener(e, "price")} /> $1001 - $2000</label>
                    </li>
                    <li className="mb-3">
                        <label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="2001-3000" onChange={e => allFilterClickListener(e, "price")} /> $2001 - $3000</label>
                    </li>
                    <li className="mb-3">
                        <label className="text-zone-1 capitalize font-signika"> <input className="mr-1" type="checkbox" name="filter" value="3001-9999" onChange={e => allFilterClickListener(e, "price")} /> $3001+</label>
                    </li>
                </ul>
            </section>
        </aside>
    )
}

export default CompareFilter